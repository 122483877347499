import { ApprovalStatus } from "@prisma/client";
import { intersection, isArray, isEmpty } from "lodash";
import { DateTime } from "luxon";
import { NoOverrideGlobalStaticConfig } from "../config/static.config";
import { InterviewFormType } from "../enums/report.enums";
import {
  ReportEditorBlock,
  ReportEditorParentSection,
  ReportEditorSubsection,
  ReportFormSubmissions,
  ReportInterviewForm,
  ReportNeedGroup,
  SingleReport,
} from "../types/assessment/assessment.report.types";
import { ExtendedAppointment, SearchAppointmentsQuery } from "../types/calendar/appointment.types";
import { filterExists, mapExists } from "./collection.util";
import { getCommaList } from "./string.util";

export const getFlattenedBlocks = (report: SingleReport): (ReportEditorBlock & { parentSectionId: string })[] =>
  report.editorSections.flatMap(section =>
    section.children.flatMap(child => {
      switch (child.type) {
        case "block":
          return [{ ...child, parentSectionId: section.reportSectionTemplateId }];
        case "subsection":
          return child.blocks.map(b => ({ ...b, parentSectionId: child.reportSectionTemplateId }));
      }
    }),
  );

type PartialReport = {
  testUploads: { reportTestUploadSourceId: string }[];
  formSubmissions: ReportFormSubmissions;
};

const getTestingPlanIncludeFilter =
  (fieldKey: string, optionKeys: string[], test: "and" | "or" = "or") =>
  (report: PartialReport): boolean => {
    if (!report.formSubmissions.testingPlanAnswers) return false;

    const field = report.formSubmissions.testingPlanAnswers[fieldKey];
    if (!isArray(field)) return false;

    switch (test) {
      case "and":
        return !optionKeys.some(k => !field.includes(k));
      case "or":
        return optionKeys.some(k => field.includes(k));
    }
  };

const CUSTOM_SECTION_FILTERS: { title: string; filter: (r: PartialReport) => boolean }[] = [
  {
    title: "Functional Communication Profile",
    filter: getTestingPlanIncludeFilter("criterion-measures", ["functional_comm_profile"]),
  },
  {
    title: "Speech/Language Sample",
    filter: getTestingPlanIncludeFilter("criterion-measures", ["speech_sample", "language_sample"]),
  },
  {
    title: "EVT-3 & PPVT-5 Comparison",
    filter: getTestingPlanIncludeFilter("performance-measures", ["evt-3", "ppvt-5"], "and"),
  },
  {
    title: "Table K: PPVT-5 / EVT-3 Receptive-Expressive Comparison Score Summary",
    filter: getTestingPlanIncludeFilter("performance-measures", ["evt-3", "ppvt-5"], "and"),
  },
  {
    title: "Caregiver, Teacher, & Student Input",
    filter: getTestingPlanIncludeFilter("non-test-measure", [
      "pl_clinical_interview",
      "pl_collateral_interview",
      "pl_teacher_interview",
    ]),
  },
];

// can be called for both sections and blocks
export const isEditorItemVisible = (
  report: PartialReport,
  item: { testUploadSourceIds: string[]; title?: string },
): boolean => {
  const custom = CUSTOM_SECTION_FILTERS.find(f => item.title === f.title);
  if (custom) return custom.filter(report);

  // item is visible if it does not include any test upload source ids
  if (isEmpty(item.testUploadSourceIds)) return true;

  // item is visible if any of the test upload source ids are present on the report
  const enabledUploadSourceIds = report.testUploads.map(u => u.reportTestUploadSourceId);
  const matchingUploadSourceIds = intersection(enabledUploadSourceIds, item.testUploadSourceIds);

  return !isEmpty(matchingUploadSourceIds);
};

export const getParentSectionVisiblity = (report: SingleReport, section: ReportEditorParentSection) => {
  const allSubsections = mapExists(section.children, c => c.type === "subsection" && (c as ReportEditorSubsection));
  const visibleSubsections = allSubsections.filter(s => isEditorItemVisible(report, s));

  const areAllSubsectionsHidden = isEmpty(visibleSubsections) && !isEmpty(allSubsections);
  const isDisabled = !isEditorItemVisible(report, section);

  return {
    isHidden: areAllSubsectionsHidden || isDisabled,
    visibleSubsections,
  };
};

export const resolveBlockContentWarning = (block: ReportEditorBlock): string | null =>
  (!block.custom?.content && block.emptyWarningNoteText) || null;

export const isCustomTableEmpty = (block: ReportEditorBlock): boolean =>
  isEmpty(block.custom?.tableRows?.filter(r => !isEmpty(filterExists(r.cellValues))));

export const resolveBlockTableWarning = (block: ReportEditorBlock): string | null => {
  if (isCustomTableEmpty(block) && block.table?.emptyWarningNoteText) return block.table.emptyWarningNoteText;

  const warningColumn = block.table?.columns.find(
    (c, i) => c.emptyWarningNoteText && !block.custom?.tableRows?.some(r => r.cellValues[i]),
  );
  return warningColumn?.emptyWarningNoteText || null;
};

export const getTestingSessionAppointmentQuery = (
  report: SingleReport,
  config: NoOverrideGlobalStaticConfig,
): SearchAppointmentsQuery => ({
  providerId: report.providerId,
  studentId: report.clientId,
  appointmentTypeIds: [config.testingSessionAppointmentTypeId],
  appointmentStatus: ["OCCURRED"],
});

export const getGeneralInformationData = (
  { client: { fullName, birthDate, pronouns, campus, grade }, provider }: SingleReport,
  testingSessions?: ExtendedAppointment[],
): { clientData: [string, string][]; reportData: [string, string][] } => {
  let birthDateString: string = "";
  if (birthDate) {
    const parsedBirthDate = birthDate?.toLocaleString(DateTime.DATE_FULL);
    const ageYears = Math.floor(Math.abs(birthDate.diffNow("years").years));
    birthDateString = `${parsedBirthDate} (Age ${ageYears})`;
  }
  return {
    clientData: [
      ["Student Name", fullName],
      ["Date of Birth", birthDateString],
      ["Gender Pronouns", pronouns || ""],
      ["Campus", campus?.name || ""],
      ["Grade", grade || ""],
    ],
    reportData: [
      ["Clinician", provider.fullName],
      ["Assessment Dates", testingSessions?.map(a => a.startTime.toLocaleString(DateTime.DATE_SHORT)).join(", ") || ""],
      // TODO show date report was finished + approved here once available
      ["Report Date", ""],
    ],
  };
};

export const getNeedGroupContent = (needGroup: ReportNeedGroup) => {
  const listItems = needGroup.recommendations.map(r => `<li>${r.text}</li>`);
  const content = needGroup.content || `<ul>${listItems.join("")}</ul>`;

  return {
    content,
    header: getCommaList(
      needGroup.needs.map(n => n.name),
      { delimiter: "&" },
    ),
  };
};

type ApprovalAction = { statusUpdate: ApprovalStatus; label: string };

export const getApprovalActions = (report: SingleReport, userId: string): ApprovalAction[] => {
  switch (report.approval?.approvalStatus) {
    case undefined:
    case "FOR_REVIEW":
      return report.providerId === userId ? [{ statusUpdate: "PENDING", label: "Submit Report" }] : [];
    case "PENDING":
      return report.reviewerId === userId
        ? [
            { statusUpdate: "APPROVED", label: "Approve Report" },
            { statusUpdate: "FOR_REVIEW", label: "Notify Provider" },
          ]
        : [];
    case "DENIED":
      return report.reviewerId === userId ? [{ statusUpdate: "APPROVED", label: "Approve Report" }] : [];
    default:
      return [];
  }
};

export type InterviewFormKey = "caregiverInterview" | "studentInterview" | "teacherInterview";

export const getInterviewFormKey = (interviewType: InterviewFormType): InterviewFormKey => {
  switch (interviewType) {
    case "caregiver":
      return "caregiverInterview";
    case "student":
      return "studentInterview";
    case "teacher":
      return "teacherInterview";
  }
};

export const getInterviews = (
  report: SingleReport | undefined,
  interviewType: InterviewFormType,
): ReportInterviewForm[] => {
  if (!report) return [];
  switch (interviewType) {
    case "caregiver":
      return report.formSubmissions.caregiverInterview;
    case "student":
      return filterExists([report.formSubmissions.studentInterview]);
    case "teacher":
      return report.formSubmissions.teacherInterview;
  }
};

export const INTERVIEW_TYPE_LABELS = {
  caregiver: "Caregiver",
  student: "Student",
  teacher: "Teacher",
};
