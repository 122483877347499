import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/material/styles";
import { ClientLogger } from "../../../util/logging.util";

export const CopyIconButton = ({
  text,
  label,
  sx,
  logger,
}: {
  text: string;
  label?: string;
  sx?: SxProps;
  logger?: ClientLogger;
}) => {
  const [isDone, setIsDone] = useState(false);
  const onClick = () => {
    navigator.clipboard.writeText(text);
    const message = `Copied ${label} Content to Clipboard`;
    setIsDone(true);
    setTimeout(() => setIsDone(false), 1000);
    logger?.info(message);
  };
  return (
    <IconButton onClick={onClick} size="small" sx={sx}>
      {isDone ? <CheckIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
    </IconButton>
  );
};
