import { ExtendedReportTemplate } from "@parallel/vertex/types/assessment/report.template.types";
import { BaseAPI } from "@/api/base.api";
import config from "@/config";

export class ReportTemplateAPI extends BaseAPI {
  constructor() {
    super({
      parseTimestamps: true,
      parseQueryNulls: true,
      baseURL: config.nexusReportServerUrl,
    });
  }

  public getReportTemplate = (templateId: string): Promise<ExtendedReportTemplate> =>
    this.instance.get(`assessment/report/template/${templateId}`).then(r => r.data);
}
