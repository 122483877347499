import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { FullBox, FullCenterBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { getCommaList } from "@parallel/vertex/util/string.util";
import ReportContent from "@/components/report/ReportContent";
import ReportSidebar from "@/components/report/ReportSidebar";
import ReportSubHeader from "@/components/report/ReportSubHeader";
import DeleteDialog from "@/components/shared/feedback/DeleteDialog";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const ReportScreen = () => {
  const {
    reportStore: {
      currentReport: report,
      fetchStatus,
      loadReport,
      setPendingDeleteReportEligibilityId,
      pendingDeleteEligibility,
      deletePendingReportEligibility,
      setPendingDeleteNeedGroupId,
      pendingDeleteNeedGroup,
      deletePendingNeedGroup,
    },
  } = useContext(StoreContext);

  const { reportId } = useParams();
  useEffect(() => {
    loadReport(reportId);
  }, [reportId]);

  if (fetchStatus === "not-found") return <NotFoundScreen />;
  if (["loading", "waiting"].includes(fetchStatus) || !report) return <LoadingScreen />;

  const pendingDeleteNeedGroupName =
    pendingDeleteNeedGroup &&
    getCommaList(
      pendingDeleteNeedGroup.needs.map(n => n.name),
      { delimiter: "&" },
    );

  return (
    <PrimaryLayout headerContent={<ReportSubHeader report={report} />} containerStyles={{ p: 0 }}>
      <FullStack direction="row">
        <Box width={300} height="100%" p={2} sx={{ borderRight: 1, borderColor: "grey.300", overflowY: "auto" }}>
          <ReportSidebar />
        </Box>
        <FullCenterBox flex="1 1 0%" sx={{ overflowY: "auto" }}>
          <FullBox height="100%" maxWidth={1200} p={2}>
            <ReportContent />
          </FullBox>
        </FullCenterBox>
      </FullStack>
      {pendingDeleteEligibility && (
        <DeleteDialog
          text={`Remove ${pendingDeleteEligibility.name}? This action cannot be undone.`}
          onDelete={deletePendingReportEligibility}
          onClose={() => setPendingDeleteReportEligibilityId(undefined)}
        />
      )}
      {pendingDeleteNeedGroupName && (
        <DeleteDialog
          text={`Remove recommendations for needs ${pendingDeleteNeedGroupName}? This action cannot be undone.`}
          onDelete={deletePendingNeedGroup}
          onClose={() => setPendingDeleteNeedGroupId(undefined)}
        />
      )}
    </PrimaryLayout>
  );
};

export default observer(ReportScreen);
