import { arrayMove } from "@dnd-kit/sortable";
import { debounce, findIndex, last, pick, set } from "lodash";
import { DateTime } from "luxon";
import { action, makeAutoObservable, runInAction } from "mobx";
import { v4 as uuid } from "uuid";
import { ClientLogger } from "@parallel/polygon/util/logging.util";
import { INTERVIEW_FORM_TYPES, InterviewFormType } from "@parallel/vertex/enums/report.enums";
import { ApprovalStatus } from "@parallel/vertex/types/approval.types";
import {
  CreateReportBody,
  ExtendedAssessmentEnrollment,
  ExtendedReport,
  ExtendedReportTestUpload,
  SingleReport,
  UpdateReportBlockBody,
  UpdateReportUploadBody,
  UpdateReportSectionBody,
  Need,
  ExtendedEligibility,
  CreateReportEligibilityBody,
  CreateReportNeedGroupBody,
  CustomBlock,
  ReportInterviewForm,
  ReportEditorSubsection,
  ExtendedReportDocumentUpload,
  ExtendedReportInterviewee,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { FormAnswers } from "@parallel/vertex/types/form.types";
import { UpdateStudentBody } from "@parallel/vertex/types/user/student.types";
import {
  getFlattenedBlocks,
  getInterviewFormKey,
  getInterviews,
  getTestingSessionAppointmentQuery,
} from "@parallel/vertex/util/assessment.report.util";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { CalendarAPI } from "@/api/calendar.api";
import { ReportAPI, ReportDocumentFileUploadRequest, ReportTestFileUploadRequest } from "@/api/report.api";
import config from "@/config";
import { ReportMenuSelection } from "@/util/report.util";
import { FetchStatus } from "@/util/shared.util";

export class ReportStore {
  public fetchStatus: FetchStatus = "waiting";
  public isEditorLoading: boolean = false;

  public createReportEnrollment?: ExtendedAssessmentEnrollment = undefined;
  public currentReport?: SingleReport = undefined;

  public testingSessions?: ExtendedAppointment[] = undefined;
  public eligibilities?: ExtendedEligibility[] = undefined;
  public needs?: Need[] = undefined;

  public menuSelection: ReportMenuSelection = { type: "data", section: "testing-plan" };

  private editorSectionComponents: Record<
    string,
    { container: HTMLDivElement; observer: IntersectionObserver; isVisible?: boolean }
  > = {};

  public pendingDeleteEligibilityId?: string = undefined;
  public pendingDeleteNeedGroupId?: string = undefined;

  public latestUpdateKey?: string = undefined;

  private isBuildPending: boolean = false;

  constructor(
    private calendarApi: CalendarAPI,
    private reportApi: ReportAPI,
    private logger: ClientLogger,
  ) {
    makeAutoObservable(this);
  }

  get canEdit(): boolean {
    if (!this.currentReport) return false;
    if (this.isBuildPending) return false;
    return !this.currentReport.approval || this.currentReport.approval.approvalStatus === "FOR_REVIEW";
  }

  get orderedSectionIds(): string[] {
    if (!this.currentReport) return [];
    return this.currentReport.editorSections.flatMap(parent => {
      const subsectionIds =
        parent.title === "Recommendations"
          ? mapExists(this.currentReport?.needGroups, g => g.groupId)
          : mapExists(parent.children, c => c.type === "subsection" && c.reportSectionTemplateId);
      return [parent.reportSectionTemplateId, ...subsectionIds];
    });
  }

  get allInterviewSubmissions(): (ReportInterviewForm & { interviewType: InterviewFormType })[] {
    const report = this.currentReport;
    if (!report) return [];

    return INTERVIEW_FORM_TYPES.flatMap(interviewType =>
      getInterviews(report, interviewType).map(s => ({ ...s, interviewType })),
    );
  }

  get pendingDeleteEligibility() {
    if (!this.pendingDeleteEligibilityId) return;
    return this.eligibilities?.find(e => e.eligibilityId === this.pendingDeleteEligibilityId);
  }

  get pendingDeleteNeedGroup() {
    if (!this.pendingDeleteNeedGroupId) return;
    return this.currentReport?.needGroups.find(g => g.groupId === this.pendingDeleteNeedGroupId);
  }

  public loadCreateReportEnrollment = async (serviceLineClientId: string | undefined) => {
    if (!serviceLineClientId) {
      this.createReportEnrollment = undefined;
      this.fetchStatus = "finished";
      return;
    }
    if (this.createReportEnrollment?.serviceLineClientId === serviceLineClientId) {
      this.fetchStatus = "finished";
      return;
    }
    this.fetchStatus = "loading";

    const reportStudent = await this.reportApi.getAssessmentEnrollment(serviceLineClientId).catch(e => {
      this.logger.error("error resolving eligible report student", { causedBy: e, context: { serviceLineClientId } });
      return undefined;
    });
    runInAction(() => {
      this.createReportEnrollment = reportStudent;
      this.fetchStatus = reportStudent ? "finished" : "not-found";
    });
  };

  public loadReport = async (reportId: string | undefined) => {
    if (!reportId) {
      this.fetchStatus = "not-found";
      return;
    }
    this.fetchStatus = "loading";

    const report =
      this.currentReport?.reportId !== reportId
        ? await this.reportApi.getReport(reportId).catch(e => {
            this.logger.logFailure("loadReportRecordFetch", e, { context: { reportId } });
            return undefined;
          })
        : this.currentReport;

    if (!report) {
      runInAction(() => {
        this.currentReport = undefined;
        this.fetchStatus = "not-found";
      });
      return;
    }

    try {
      await Promise.all([this.loadTestingSessions(report), await this.loadNeeds(), await this.loadEligibilities()]);
    } catch (e) {
      this.logger.logFailure("loadReportLibraryFetches", e);
    }

    runInAction(() => {
      this.currentReport = report;
      this.fetchStatus = "finished";
      this.menuSelection = this.getDefaultMenuSelection(report);
    });
  };

  private getDefaultMenuSelection = (report: SingleReport): ReportMenuSelection => {
    if (!report.formSubmissions.testingPlan.hasAnswers) return { type: "data", section: "testing-plan" };

    if (report.testUploads.some(u => !u.fileName && !u.skipReason))
      return { type: "data", section: "tests-administered" };

    return { type: "editor", sectionId: report.editorSections[0].reportSectionTemplateId };
  };

  public registerEditorSection = (sectionId: string, container: HTMLDivElement) => {
    if (this.editorSectionComponents[sectionId]) return;

    // performing delayed auto-scroll here b/c when navigating from data section to editor, editor containers haven't registered yet
    if (
      this.menuSelection.type === "editor" &&
      this.menuSelection.sectionId === sectionId &&
      sectionId !== this.currentReport?.editorSections[0].reportSectionTemplateId
    ) {
      container.scrollIntoView();
    }

    const observer = new IntersectionObserver(([entry]) => {
      this.onEditorSectionVisibilityChange(sectionId, entry.isIntersecting);
    });
    observer.observe(container);

    this.editorSectionComponents[sectionId] ||= { container, observer };
  };

  public clearEditorComponents = () => {
    Object.values(this.editorSectionComponents).forEach(({ observer }) => observer.disconnect());
    this.editorSectionComponents = {};
  };

  private setSelectedEditorSection = debounce(() => {
    const sectionId = this.orderedSectionIds.find(id => this.editorSectionComponents[id]?.isVisible);
    if (!sectionId) return;
    runInAction(() => (this.menuSelection = { type: "editor", sectionId }));
  }, 100);

  private onEditorSectionVisibilityChange = (sectionId: string, isVisible: boolean) => {
    if (!this.editorSectionComponents[sectionId]) return;
    this.editorSectionComponents[sectionId].isVisible = isVisible;
    this.setSelectedEditorSection();
  };

  public setMenuSelection = (selection: ReportMenuSelection) => {
    // when initially navigating to editor, flash loading flag so loading spinner shows while components load
    if (selection.type === "editor" && this.menuSelection.type !== "editor") {
      this.isEditorLoading = true;
      setTimeout(
        action(() => (this.isEditorLoading = false)),
        100,
      );
    }
    this.menuSelection = selection;

    if (selection.type !== "editor") {
      this.clearEditorComponents();
      return;
    }
    const { container } = this.editorSectionComponents[selection.sectionId] || {};
    container?.scrollIntoView();
  };

  public loadTestingSessions = async (report: SingleReport) => {
    const testingSessions = await this.calendarApi.searchAppointments(
      getTestingSessionAppointmentQuery(report, config),
    );
    runInAction(() => (this.testingSessions = testingSessions.records));
  };

  public loadEligibilities = async () => {
    const eligibilities = await this.reportApi.getAllEligibilities();
    runInAction(() => (this.eligibilities = eligibilities));
  };

  public loadNeeds = async () => {
    const needs = await this.reportApi.getAllNeeds();
    this.needs = needs;
  };

  public setCreateReportEnrollment = (enrollment?: ExtendedAssessmentEnrollment) => {
    this.createReportEnrollment = enrollment;
  };

  public createReport = async (body: CreateReportBody): Promise<ExtendedReport> => {
    const report = await this.reportApi.createReport(body);
    runInAction(() => (this.currentReport = report));
    return report;
  };

  public updateCurrentReport = async (performUpdate: (reportId: string) => Promise<SingleReport>) => {
    if (!this.currentReport) return;
    const updated = await performUpdate(this.currentReport.reportId);
    runInAction(() => (this.currentReport = updated));
    return updated;
  };

  public updateCurrentReportStudent = async (body: UpdateStudentBody) =>
    this.updateCurrentReport(reportId => this.reportApi.updateReportStudent(reportId, body));

  public submitTestingPlan = async (formAnswers: FormAnswers) => {
    const updatedReport = await this.updateCurrentReport(reportId =>
      this.reportApi.updateTestingPlan(reportId, { formAnswers }),
    );
    if (!updatedReport) return;
    runInAction(() => (this.menuSelection = this.getDefaultMenuSelection(updatedReport)));
  };

  public uploadTestFiles = async (files: ReportTestFileUploadRequest[]) => {
    if (!this.currentReport) return;
    await this.updateCurrentReport(reportId => this.reportApi.uploadTestFiles(reportId, files));
  };

  public updateTestUpload = async (uploadId: string, body: UpdateReportUploadBody) => {
    if (!this.currentReport) return;
    const updatedUpload = await this.reportApi.updateTestUpload(this.currentReport.reportId, uploadId, body);
    this.updateCurrentTestUploads([{ updatedUpload, currentUploadId: uploadId }]);
  };

  public deleteTestUpload = async (uploadId: string) =>
    this.updateCurrentReport(reportId => this.reportApi.deleteTestUpload(reportId, uploadId));

  private updateCurrentTestUploads = (
    updates: { updatedUpload: ExtendedReportTestUpload; currentUploadId?: string }[],
  ) => {
    updates.forEach(({ updatedUpload, currentUploadId }) => {
      if (!this.currentReport) return;

      const replaceUploadId = currentUploadId || updatedUpload.reportTestUploadId;
      const index = findIndex(this.currentReport.testUploads, u => u.reportTestUploadId === replaceUploadId);

      if (updatedUpload.archivedAt) {
        this.currentReport.testUploads.splice(index, 1);
        return;
      }

      index === -1
        ? this.currentReport.testUploads.push(updatedUpload)
        : set(this.currentReport, `testUploads.[${index}]`, updatedUpload);
    });
  };

  private updateCurrentDocumentUploads = (
    updates: { updatedUpload: ExtendedReportDocumentUpload; currentUploadId?: string }[],
  ) => {
    updates.forEach(({ updatedUpload, currentUploadId }) => {
      if (!this.currentReport) return;

      const replaceUploadId = currentUploadId || updatedUpload.reportDocumentUploadId;
      const index = findIndex(this.currentReport.documentUploads, u => u.reportDocumentUploadId === replaceUploadId);

      if (updatedUpload.archivedAt) {
        this.currentReport.documentUploads.splice(index, 1);
        return;
      }

      index === -1
        ? this.currentReport.documentUploads.push(updatedUpload)
        : set(this.currentReport, `documentUploads.[${index}]`, updatedUpload);
    });
  };

  public uploadDocuments = async (files: ReportDocumentFileUploadRequest[]) => {
    if (!this.currentReport) return;
    const uploads = await this.reportApi.uploadDocumentFiles(this.currentReport.reportId, files);
    this.updateCurrentDocumentUploads(
      uploads.map(u => ({ updatedUpload: { ...u, createdAt: DateTime.fromJSDate(u.createdAt) } })),
    );
  };

  public deleteDocumentUpload = async (uploadId: string) =>
    this.updateCurrentReport(reportId => this.reportApi.deleteDocumentUpload(reportId, uploadId));

  public upsertCustomSection = async (sectionTemplateId: string, body: UpdateReportSectionBody): Promise<void> => {
    const report = this.currentReport;
    if (!report) return;

    const customSection = await this.reportApi.upsertCustomSection(report.reportId, sectionTemplateId, body);

    const updatedSections = report.editorSections.map(section => {
      if (section.reportSectionTemplateId === sectionTemplateId) return { ...section, custom: customSection };

      const updatedChildren = section.children.map(child =>
        child.type === "subsection" && child.reportSectionTemplateId === sectionTemplateId
          ? { ...child, custom: customSection }
          : child,
      );
      return { ...section, children: updatedChildren };
    });
    runInAction(() => (this.currentReport = { ...report, editorSections: updatedSections }));
  };

  public upsertCustomBlock = async (blockTemplateId: string, body: UpdateReportBlockBody): Promise<void> => {
    const report = this.currentReport;
    if (!report) return;

    const insertUpdatedCustomBlock = (updatedCustomBlock: CustomBlock): SingleReport => {
      const updatedSections = report.editorSections.map(section => {
        const updatedChildren = section.children.map(child => {
          switch (child.type) {
            case "block":
              return child.reportBlockTemplateId === blockTemplateId ? { ...child, custom: updatedCustomBlock } : child;
            case "subsection":
              const updatedBlocks = child.blocks.map(block =>
                block.reportBlockTemplateId === blockTemplateId ? { ...block, custom: updatedCustomBlock } : block,
              );
              return { ...child, blocks: updatedBlocks };
          }
        });
        return { ...section, children: updatedChildren };
      });
      return { ...report, editorSections: updatedSections };
    };

    return this.performSequentialUpdate(() =>
      this.reportApi.upsertCustomBlock(report.reportId, blockTemplateId, body).then(insertUpdatedCustomBlock),
    );
  };

  public updateSubsectionOrder = async (movedSectionTemplateId: string, overSectionTemplateId: string) => {
    if (!this.currentReport) return;

    const parentSection = this.currentReport?.editorSections.find(parent =>
      parent.children.some(
        child => child.type === "subsection" && child.reportSectionTemplateId === movedSectionTemplateId,
      ),
    );
    if (!parentSection) return;

    const getChildIndex = (sectionTemplateId: string) =>
      findIndex(
        parentSection.children,
        c => c.type === "subsection" && c.reportSectionTemplateId === sectionTemplateId,
      );
    const fromIndex = getChildIndex(movedSectionTemplateId);
    const toIndex = getChildIndex(overSectionTemplateId);

    const updatedParentSection = {
      ...parentSection,
      children: arrayMove(parentSection.children, fromIndex, toIndex),
    };
    this.currentReport = {
      ...this.currentReport,
      editorSections: this.currentReport?.editorSections.map(s =>
        s.reportSectionTemplateId === parentSection.reportSectionTemplateId ? updatedParentSection : s,
      ),
    };

    const body = { fromIndex, toIndex };
    await this.reportApi.updateSubsectionOrder(
      this.currentReport.reportId,
      parentSection.reportSectionTemplateId,
      body,
    );
  };

  public updateNeedGroupOrder = async (movedGroupId: string, overGroupId: string) => {
    if (!this.currentReport) return;
    const { reportId, needGroups } = this.currentReport;

    const fromIndex = findIndex(needGroups, g => g.groupId === movedGroupId);
    const toIndex = findIndex(needGroups, g => g.groupId === overGroupId);

    this.currentReport = {
      ...this.currentReport,
      needGroups: arrayMove(needGroups, fromIndex, toIndex),
    };

    await this.reportApi.updateNeedGroupOrder(reportId, { fromIndex, toIndex });
  };

  public updateBlockTableCellValue = async (
    blockTemplateId: string,
    rowIndex: number,
    cellIndex: number,
    value: string,
  ): Promise<void> => {
    if (!this.currentReport) return;

    const block = getFlattenedBlocks(this.currentReport).find(b => b.reportBlockTemplateId === blockTemplateId);
    if (!block) return;

    const tableRows = block.custom?.tableRows || block.table?.defaultRows;
    if (!tableRows) return;

    const updatedTableRows = tableRows.map((row, i) =>
      i === rowIndex
        ? { key: row.key, cellValues: row.cellValues.map((cell, j) => (j === cellIndex ? value : cell)) }
        : row,
    );
    return this.upsertCustomBlock(block.reportBlockTemplateId, { tableRows: updatedTableRows });
  };

  private performSequentialUpdate = async (update: () => Promise<SingleReport>) => {
    const updateKey = uuid();
    this.latestUpdateKey = updateKey;
    const updatedReport = await update();
    if (this.latestUpdateKey !== updateKey) return;
    runInAction(() => (this.currentReport = updatedReport));
  };

  private appendInterviewSubmission = (interviewType: InterviewFormType, submission: ReportInterviewForm) => {
    if (!this.currentReport) return;
    switch (interviewType) {
      case "student":
        this.currentReport.formSubmissions.studentInterview = submission;
        return;
      case "caregiver":
        this.currentReport.formSubmissions.caregiverInterview.push(submission);
        return;
      case "teacher":
        this.currentReport.formSubmissions.teacherInterview.push(submission);
        return;
    }
  };

  public createInterviewSubmission = async (interviewType: InterviewFormType, intervieweeName?: string) => {
    if (!this.currentReport) return;
    const submission = await this.reportApi.createInterviewNotesSubmission(
      {
        interviewType,
        reportId: this.currentReport.reportId,
      },
      intervieweeName,
    );
    this.appendInterviewSubmission(interviewType, { ...submission, intervieweeName });

    runInAction(
      () => (this.menuSelection = { type: "interview", submissionId: submission.submissionId, interviewType }),
    );

    return submission;
  };

  public updateIntervieweeName = async (
    interviewType: InterviewFormType,
    submissionId: string,
    intervieweeName: string,
  ) => {
    const report = this.currentReport;
    if (!report) return;

    // update app state first to handle race conditions
    this.updateInterviewee(report, interviewType, submissionId, { intervieweeName });

    await this.reportApi.updateIntervieweeName(
      { interviewType, reportId: report.reportId },
      submissionId,
      intervieweeName,
    );
  };

  public sendInterviewEmail = async (interviewType: InterviewFormType, submissionId: string, toEmail: string) => {
    const report = this.currentReport;
    if (!report) return;

    const updatedInterviewee = await this.reportApi.sendInterviewEmail(
      { interviewType, reportId: report.reportId },
      submissionId,
      toEmail,
    );
    this.updateInterviewee(report, interviewType, submissionId, updatedInterviewee);
  };

  private updateInterviewee = (
    report: SingleReport,
    interviewType: InterviewFormType,
    submissionId: string,
    interviewee: ExtendedReportInterviewee,
  ) => {
    const key = getInterviewFormKey(interviewType);
    const updatedSubmissions = getInterviews(report, interviewType).map(s =>
      s.submissionId === submissionId ? { ...s, ...interviewee } : s,
    );
    this.currentReport = {
      ...report,
      formSubmissions: {
        ...report.formSubmissions,
        [key]: key === "studentInterview" ? updatedSubmissions[0] : updatedSubmissions,
      },
    };
  };

  public submitInterviewSubmission = async (
    interviewType: InterviewFormType,
    submissionId: string,
    formAnswers: FormAnswers,
  ) =>
    this.updateCurrentReport(reportId =>
      this.reportApi.submitInterviewNotesSubmission({ reportId, interviewType }, submissionId, formAnswers),
    );

  public deleteInterviewSubmission = async (interviewType: InterviewFormType, submissionId: string) => {
    const currentIndex = this.allInterviewSubmissions.findIndex(s => s.submissionId === submissionId);

    await this.updateCurrentReport(reportId =>
      this.reportApi.deleteInterviewNotesSubmission({ reportId, interviewType }, submissionId),
    );

    const nextSelected = this.allInterviewSubmissions[currentIndex] || last(this.allInterviewSubmissions);
    runInAction(
      () =>
        (this.menuSelection = nextSelected
          ? { type: "interview", ...pick(nextSelected, "submissionId", "interviewType") }
          : this.getDefaultMenuSelection(this.currentReport!)),
    );
  };

  public createReportEligibility = async (eligibilityId: string, body: CreateReportEligibilityBody) => {
    if (!this.currentReport) return;
    const updatedReport = await this.reportApi.createReportEligibility(
      this.currentReport.reportId,
      eligibilityId,
      body,
    );
    runInAction(() => (this.currentReport = updatedReport));

    const [addedSubsection] = mapExists(updatedReport.editorSections, s => {
      const subsection = s.children.find(c => c.type === "subsection" && c.eligibilityId === eligibilityId);
      return subsection as ReportEditorSubsection | undefined;
    });
    if (addedSubsection)
      runInAction(() => (this.menuSelection = { type: "editor", sectionId: addedSubsection.reportSectionTemplateId }));
  };

  public setPendingDeleteReportEligibilityId = (eligibilityId: string | undefined) => {
    this.pendingDeleteEligibilityId = eligibilityId;
  };

  public deletePendingReportEligibility = async () => {
    if (!this.currentReport) return;
    if (!this.pendingDeleteEligibilityId) return;
    const updatedReport = await this.reportApi.deleteReportEligibility(
      this.currentReport.reportId,
      this.pendingDeleteEligibilityId,
    );
    runInAction(() => {
      this.currentReport = updatedReport;
      this.pendingDeleteEligibilityId = undefined;
    });
  };

  public createReportNeedGroups = async (groups: CreateReportNeedGroupBody[]) =>
    this.updateCurrentReport(reportId => this.reportApi.createReportNeedGroups(reportId, groups));

  public updateNeedGroupContent = async (groupId: string, content: string) => {
    const report = this.currentReport;
    if (!report) return;
    const updatedGroup = await this.reportApi.updateNeedGroupContent(report.reportId, groupId, content);
    const updatedReportGroups = report.needGroups.map(g => (g.groupId === groupId ? updatedGroup : g));
    runInAction(() => (this.currentReport = { ...report, needGroups: updatedReportGroups }));
  };

  public setPendingDeleteNeedGroupId = (groupId: string | undefined) => {
    this.pendingDeleteNeedGroupId = groupId;
  };

  public deletePendingNeedGroup = async () => {
    const needGroupId = this.pendingDeleteNeedGroupId;
    if (!needGroupId) return;
    await this.updateCurrentReport(reportId => this.reportApi.deleteNeedGroup(reportId, needGroupId));
  };

  public buildReport = async (reportId: string) => {
    this.isBuildPending = true;
    return this.reportApi.buildReport(reportId).finally(action(() => (this.isBuildPending = false)));
  };

  public uploadSignature = async (imageFile: File) => {
    const report = this.currentReport;
    if (!report) return;
    const { url } = await this.reportApi.uploadSignature(report.reportId, imageFile);
    runInAction(() => (this.currentReport = { ...report, signatureImageUrl: url }));
  };

  public deleteSignature = async () => {
    const report = this.currentReport;
    if (!report) return;
    await this.reportApi.deleteSignature(report.reportId);
    runInAction(() => (this.currentReport = { ...report, signatureImageUrl: undefined }));
  };

  public updateApprovalStatus = async (status: ApprovalStatus) => {
    const report = this.currentReport;
    if (!report) return;
    this.isEditorLoading = true;
    try {
      const approval = await this.reportApi.updateReportApproval(report.reportId, status);
      runInAction(() => (this.currentReport = { ...report, approval }));
    } finally {
      runInAction(() => (this.isEditorLoading = false));
    }
  };
}
