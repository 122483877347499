import { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { Button, Grid2, Stack } from "@mui/material";
import { FullGrid } from "@parallel/polygon/components/shared/layout/container";
import DocumentsUpload from "@/components/report/data/upload/DocumentsUpload";

const StudentDocuments = () => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  return (
    <FullGrid container pt={2}>
      <Stack gap={2} width="100%">
        <Grid2 container justifyContent={"right"}>
          <Button startIcon={<UploadIcon />} onClick={() => setUploadModalOpen(true)}>
            Upload Documents
          </Button>
        </Grid2>
        <DocumentsUpload uploadModalOpen={uploadModalOpen} setUploadModalOpen={setUploadModalOpen} />
      </Stack>
    </FullGrid>
  );
};

export default StudentDocuments;
