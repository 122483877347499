import { AuthAPI } from "@/api/auth.api";
import { CalendarAPI } from "@/api/calendar.api";
import { FormAPI } from "@/api/form.api";
import { InstitutionAPI } from "@/api/institution.api";
import { ProgressAPI } from "@/api/progress.api";
import { ReportAPI } from "@/api/report.api";
import { ReportTemplateAPI } from "@/api/report.template.api";
import { ServiceAPI } from "@/api/service.api";
import { StudentAPI } from "@/api/student.api";
import { UserAPI } from "@/api/user.api";

export class ApiStore {
  public authApi: AuthAPI;
  public calendarApi: CalendarAPI;
  public formApi: FormAPI;
  public institutionApi: InstitutionAPI;
  public progressApi: ProgressAPI;
  public reportApi: ReportAPI;
  public reportTemplateApi: ReportTemplateAPI;
  public serviceApi: ServiceAPI;
  public userApi: UserAPI;
  public studentApi: StudentAPI;

  constructor() {
    this.authApi = new AuthAPI();
    this.calendarApi = new CalendarAPI();
    this.formApi = new FormAPI();
    this.institutionApi = new InstitutionAPI();
    this.progressApi = new ProgressAPI();
    this.reportApi = new ReportAPI();
    this.reportTemplateApi = new ReportTemplateAPI();
    this.serviceApi = new ServiceAPI();
    this.userApi = new UserAPI();
    this.studentApi = new StudentAPI();
  }
}
