import { createContext } from "react";
import { CalendarStore } from "@/stores/calendar.store";
import { ProgressStore } from "@/stores/progress.store";
import { ReportStore } from "@/stores/report.store";
import { initLogger } from "@/util/logging.util";
import { ApiStore } from "./api.store";
import { AuthStore } from "./auth.store";

const apiStore = new ApiStore();

const authStore = new AuthStore(apiStore.authApi, apiStore.userApi);

export const getLoggerContext = () => ({ currentUser: authStore.currentUser });

const calendarStore = new CalendarStore(
  authStore,
  apiStore.calendarApi,
  apiStore.userApi,
  initLogger("CalendarStore", getLoggerContext),
);

const progressStore = new ProgressStore(apiStore.progressApi);

const reportStore = new ReportStore(
  apiStore.calendarApi,
  apiStore.reportApi,
  initLogger("ReportStore", getLoggerContext),
);

export const StoreContext = createContext({ apiStore, authStore, calendarStore, progressStore, reportStore });
