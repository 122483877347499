import { useState } from "react";
import Box from "@mui/material/Box";
import { useUpdateEffect } from "@react-hookz/web";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { PaginatedResult, PaginateParams, SortQuery } from "@parallel/vertex/types/shared.types";
import FilterSearchInput, { FilterOption, FilterProperty } from "@/components/shared/input/FilterSearchInput";
import DataTable, { DataTableAction, DataTableColumn } from "@/components/shared/layout/DataTable";

export type FetchParams = {
  page?: PaginateParams;
  filters?: FilterOption[];
  sort?: SortQuery;
};

const ReportList = <A extends object>({
  columns,
  currentPage,
  fetchPage,
  filters,
  actions,
  onRowClick,
}: {
  columns: DataTableColumn<A>[];
  currentPage?: PaginatedResult<A>;
  fetchPage: (params: FetchParams) => Promise<unknown>;
  filters: FilterProperty[];
  actions?: DataTableAction[];
  onRowClick?: (id: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState<PaginateParams>();
  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>([]);
  const [sort, setSort] = useState<SortQuery>();

  useUpdateEffect(() => {
    setIsLoading(true);
    fetchPage({ page, filters: selectedFilters, sort }).finally(() => setIsLoading(false));
  }, [page, selectedFilters, sort]);

  return (
    <FullStack gap={2}>
      <FilterSearchInput
        properties={filters}
        selectedOptions={selectedFilters}
        setSelectedOptions={setSelectedFilters}
      />
      <Box sx={{ height: 0, width: "100%", flex: "1 1 0%" }}>
        <DataTable
          columns={columns}
          data={currentPage?.records}
          pagination={{
            initialPageSize: page?.pageSize || 25,
            onPageChange: setPage,
            totalCount: currentPage?.totalCount || 0,
          }}
          onSortChange={setSort}
          actions={actions}
          onRowClick={onRowClick}
          isLoading={isLoading}
        />
      </Box>
    </FullStack>
  );
};

export default ReportList;
