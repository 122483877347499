import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { FormAnswers } from "@parallel/vertex/types/form.types";
import { ReportContentLayout } from "@/components/report/ReportContent";
import FeatheryForm from "@/components/shared/display/FeatheryForm";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ReportTestingPlan", getLoggerContext);

const ReportTestingPlan = () => {
  const {
    reportStore: { currentReport: report, submitTestingPlan },
  } = useContext(StoreContext);

  if (!report) return <></>;

  const { featheryFormId, submissionId } = report.formSubmissions.testingPlan;

  const onSubmit = (formAnswers: FormAnswers) =>
    submitTestingPlan(formAnswers).catch(logger.handleFailureAndThrow("updateTestingPlan", { level: "warning" }));

  return (
    <ReportContentLayout title="Testing Plan">
      <FeatheryForm featheryFormId={featheryFormId} submissionId={submissionId} onSubmit={onSubmit} />
    </ReportContentLayout>
  );
};

export default observer(ReportTestingPlan);
