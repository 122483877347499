import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";

export type NoteSeverity = "info" | "warn";

const useSeverityProps = (severity: NoteSeverity) => {
  const { palette } = useTheme();
  switch (severity) {
    case "info":
      return {
        icon: <InfoIcon color="info" />,
        bgcolor: "highlight.light",
        borderColor: "info.main",
        defaultTitle: "Tip",
      };
    case "warn":
      return {
        icon: <WarningIcon color="warning" />,
        bgcolor: alpha(palette.warning.light, 0.15),
        borderColor: "warning.main",
        defaultTitle: "Required",
      };
  }
};

const BlockNote = ({
  text,
  severity,
  isCollapsed,
  toggleCollapsed,
}: {
  text: string;
  severity: NoteSeverity;
  isCollapsed?: boolean;
  toggleCollapsed: () => void;
}) => {
  const { icon, bgcolor, borderColor, defaultTitle } = useSeverityProps(severity);

  return (
    <Stack gap={1} sx={{ p: 1, bgcolor, borderLeft: 2, borderColor }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={1}>
          {icon}
          <Typography variant="body1">{defaultTitle}</Typography>
        </Stack>
        <IconButton size="small" onClick={toggleCollapsed}>
          {isCollapsed ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
        </IconButton>
      </Stack>
      {!isCollapsed && (
        <Stack>
          {text.split("\\n").map((line, i) => (
            <Typography variant="body2" key={i}>
              {line}
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default BlockNote;
