import type {
  ApprovalStatus,
  ComplianceType,
  Institution,
  TaskType,
  TaskTypeCategory,
  TimeEntry,
} from "@prisma/client";
import { z } from "zod";
import { approvalStatusEnum } from "../../enums/approval.enums";
import { recurrenceEditModeEnum } from "../../enums/calendar.enums";
import { datetimeRequestField, paginateQueryFields } from "../shared.types";
import { ExtendedUser } from "../user/user.types";
import { ExtendedCalendarItem, timeRangeFields, timeRangeRefine } from "./calendar.types";
import { ExtendedRecurrence, recurrenceBody } from "./recurrence.types";

export type { TaskType, TaskTypeCategory } from "@prisma/client";

export type ExtendedTimeEntry = ExtendedCalendarItem<TimeEntry> & {
  taskType: TaskType;
  user: { userId: string; fullName: string; email?: string };
  students: ExtendedUser[];
  campus?: Institution;
  latestApproval?: { approvalId: string; approvalStatus: ApprovalStatus };
  recurrence?: ExtendedRecurrence;
  userName?: string; // deprecated
};

export const searchIndirectTimeSchema = z.object({
  userIds: z.string().uuid().array().optional(),
  startTime: datetimeRequestField.optional(),
  endTime: datetimeRequestField.optional(),
  taskTypeId: z.string().uuid().optional(),
  approvalStatus: approvalStatusEnum.optional(),
  ...paginateQueryFields,
});

export type SearchIndirectTimeQuery = z.infer<typeof searchIndirectTimeSchema> & {
  studentId?: string;
  complianceTypes?: ComplianceType[];
  recurrenceId?: string;
};

const indirectTimeBody = z.object({
  taskTypeId: z.string().uuid(),
  description: z.string().trim().nullable().optional(),
  userId: z.string().uuid().optional(),
  studentUserIds: z.string().uuid().array().nullable().optional(),
  campusId: z.string().uuid().nullable().optional(),
  approvalStatus: approvalStatusEnum.optional(),
  recurrence: recurrenceBody.optional(),
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
  ...timeRangeFields,
});

export const createIndirectTimeBodySchema = indirectTimeBody.refine(...timeRangeRefine);

export type CreateIndirectTimeBody = z.infer<typeof createIndirectTimeBodySchema>;

export const updateIndirectTimeBodySchema = indirectTimeBody.partial().refine(...timeRangeRefine);

export type UpdateIndirectTimeBody = z.infer<typeof updateIndirectTimeBodySchema> & { nylasEventId?: string };

export const bulkUpdateIndirectTimeBodySchema = z.record(z.string().uuid(), updateIndirectTimeBodySchema);

export type BulkUpdateIndirectTimeBody = z.infer<typeof bulkUpdateIndirectTimeBodySchema>;

export const deleteIndirectTimeBodySchema = z.object({
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export type DeleteIndirectTimeBody = z.infer<typeof deleteIndirectTimeBodySchema>;

export const searchIndirectTimeTypesQuerySchema = z.object({ providerId: z.string().optional() });

export type SearchIndirectTimeTypesQuery = z.infer<typeof searchIndirectTimeTypesQuerySchema>;

export type IndirectTimeTypeCategory = {
  value: TaskTypeCategory;
  types: TaskType[];
};
