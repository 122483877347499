import { ReactNode, useContext } from "react";
import { toast } from "react-toastify";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommentIcon from "@mui/icons-material/Comment";
import DescriptionIcon from "@mui/icons-material/Description";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FlagIcon from "@mui/icons-material/Flag";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { lighten, useTheme } from "@mui/material/styles";
import { isEmpty } from "lodash";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { friendlyReportReviewReasons } from "@parallel/vertex/enums/assessment.report.enums";
import { SingleReport } from "@parallel/vertex/types/assessment/assessment.report.types";
import { getApprovalActions, getFlattenedBlocks } from "@parallel/vertex/util/assessment.report.util";
import SubHeader from "@/components/shared/layout/SubHeader";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getShortServiceLineTitle } from "@/util/service.util";

const logger = initLogger("ReportSubHeader", getLoggerContext);

const getStatusProps = (report: SingleReport) => {
  switch (report.approval?.approvalStatus) {
    case undefined:
      return { icon: <EditNoteIcon color="primary" />, label: "In Progress" };
    case "APPROVED":
      return { icon: <CheckCircleIcon color="success" />, label: "Complete" };
    default:
      return { icon: <FlagIcon color="warning" />, label: "In Review" };
  }
};

const StatusBadge = ({ report, label, icon }: { report: SingleReport; label: string; icon: ReactNode }) => {
  const theme = useTheme();

  const content = (
    <Stack direction="row" gap={1}>
      {icon}
      <Typography variant="body1">{label}</Typography>
    </Stack>
  );

  const reviewReasons = report.approval?.reviewReasons;
  if (label !== "In Review" || !reviewReasons || isEmpty(reviewReasons)) return content;

  const popup = (
    <ul>
      {reviewReasons.map(reason => (
        <li key={reason}>
          <Typography variant="body2">{friendlyReportReviewReasons[reason]}</Typography>
        </li>
      ))}
    </ul>
  );

  return (
    <Tooltip
      title={popup}
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: 400,
            p: 2,
            bgcolor: lighten(theme.palette.warning.light, 0.7),
            color: "black",
            "& ul": {
              ml: 2,
              listStyleType: "disc",
            },
          },
        },
      }}
    >
      {content}
    </Tooltip>
  );
};

const ReportSubHeader = ({ report }: { report: SingleReport }) => {
  const {
    authStore: { currentUser },
    reportStore: { updateApprovalStatus, setMenuSelection, buildReport },
  } = useContext(StoreContext);

  const performBuild = async () => {
    const { url } = await buildReport(report.reportId).catch(
      logger.handleFailureAndReturn("buildReport", { url: null }),
    );
    if (!url) {
      toast.error("Report build failed");
      return;
    }

    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      logger.warn("upload file pop-up blocked");
      toast.error("Pop-up blocked. Please allow pop-ups for this site.");
    }
  };

  const shortName = getShortServiceLineTitle(report.serviceLine).replace("Assessment - ", "");

  const approvalActions = currentUser ? getApprovalActions(report, currentUser.userId) : [];

  const unresolvedThreadBlocks =
    report.approval?.approvalStatus === "FOR_REVIEW"
      ? getFlattenedBlocks(report).filter(b => !isEmpty(b.custom?.comments) && !b.custom?.commentsResolvedAt)
      : [];

  const unresolvedThreadCount = unresolvedThreadBlocks.length;

  const headerActions = report.formSubmissions.testingPlan.hasAnswers && (
    <Stack direction="row" gap={2}>
      {unresolvedThreadCount > 0 && (
        <Button
          onClick={() => setMenuSelection({ type: "editor", sectionId: unresolvedThreadBlocks[0].parentSectionId })}
          startIcon={<CommentIcon color="warning" />}
          sx={{ color: "warning.main" }}
        >
          {unresolvedThreadCount}
        </Button>
      )}
      {approvalActions.map(({ statusUpdate, label }) => (
        <Tooltip
          title={unresolvedThreadCount > 0 ? "Must resolve all comments before re-submitting" : undefined}
          key={statusUpdate}
        >
          <div>
            <ProcessButton
              process={() => updateApprovalStatus(statusUpdate)}
              startIcon={statusUpdate === "APPROVED" ? <AssignmentTurnedInIcon /> : <SendIcon />}
              disabled={unresolvedThreadCount > 0}
            >
              {label}
            </ProcessButton>
          </div>
        </Tooltip>
      ))}
      <ProcessButton process={performBuild} startIcon={<DescriptionIcon />}>
        Build
      </ProcessButton>
    </Stack>
  );

  const statusProps = getStatusProps(report);
  const subtitles: ReactNode[] = [
    shortName,
    `Due ${report.assessmentDueDate.toLocaleString({ month: "2-digit", day: "numeric" })}`,
    <StatusBadge report={report} {...statusProps} key="status" />,
  ];

  return (
    <SubHeader icon={<AssessmentIcon />} title={report.client.fullName} subtitle={subtitles} actions={headerActions} />
  );
};

export default ReportSubHeader;
