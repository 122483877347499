import { z } from "zod";

export const ALL_APPROVAL_STATUSES = ["APPROVED", "DENIED", "PENDING", "FOR_REVIEW"] as const;

export const approvalStatusEnum = z.enum(ALL_APPROVAL_STATUSES);

export const APPROVAL_STATUS_NAMES = {
  APPROVED: "Approved",
  DENIED: "Denied",
  PENDING: "Pending",
  FOR_REVIEW: "Provider Review",
};
