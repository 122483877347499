import { useContext, useState } from "react";
import { toast } from "react-toastify";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import Box from "@mui/material/Box";
import { useMountEffect } from "@react-hookz/web";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { ExtendedReportTestUpload } from "@parallel/vertex/types/assessment/assessment.report.types";
import SelectInput from "@/components/shared/input/SelectInput";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("SkipTestPrompt", getLoggerContext);

const SkipTestPrompt = ({ upload, onFinished }: { upload: ExtendedReportTestUpload; onFinished: () => void }) => {
  const {
    apiStore: { reportApi },
    reportStore: { updateTestUpload },
  } = useContext(StoreContext);

  const [skipReasonOptions, setSkipReasonOptions] = useState<SelectOption[]>([]);
  useMountEffect(() =>
    reportApi
      .getAllTestUploadSkipReasons()
      .then(reasons =>
        setSkipReasonOptions(reasons.map(r => ({ key: r.reportTestUploadSkipReasonId, label: r.title }))),
      ),
  );

  const [skipReasonId, setSkipReasonId] = useState("");

  const writeSkipReason = async () => {
    if (!skipReasonId) return;
    return updateTestUpload(upload.reportTestUploadId, { skipReasonId })
      .then(onFinished)
      .catch(e => {
        logger.logFailure("writeSkipReason", e);
        toast.error("Failed to mark test as skipped");
      });
  };

  return (
    <PromptLayout
      headerText={`Skip Test - ${upload.source.name}`}
      leftAction={{ fn: onFinished, label: "Cancel" }}
      rightAction={{ fn: writeSkipReason, icon: <ContentPasteOffIcon />, label: "Submit", disabled: !skipReasonId }}
    >
      <Box width={500}>
        <SelectInput
          label="Reason"
          options={skipReasonOptions}
          value={skipReasonId}
          onChange={setSkipReasonId}
          fullWidth
        />
      </Box>
    </PromptLayout>
  );
};

export default SkipTestPrompt;
