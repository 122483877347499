import { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import { REPORT_CONTENT_WIDTH } from "@/components/report/editor/ReportEditor";

const RightMarginBox = ({ children, maxHeight }: { children: ReactNode; maxHeight?: number }) => (
  <Stack
    gap={1}
    sx={{ position: "absolute", top: 0, left: REPORT_CONTENT_WIDTH, width: 300, pr: 0.5, maxHeight, overflowY: "auto" }}
  >
    {children}
  </Stack>
);

export default RightMarginBox;
