import { ReactNode, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useEventListener } from "@react-hookz/web";

const ignoreEvent = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
};

const useDragHandlers = (onFileSelect: (fs: File[]) => void) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isDragover, setIsDragover] = useState(false);
  const toggleDrag = (isDragover: boolean) => (e: Event) => {
    ignoreEvent(e);
    setIsDragover(isDragover);
  };
  const filesDropped = (e: any) => {
    toggleDrag(false)(e);
    const files: FileList | undefined = e.dataTransfer?.files;
    if (!files) return;
    onFileSelect([...files]);
  };

  useEventListener(containerRef, "drag", ignoreEvent);
  useEventListener(containerRef, "dragstart", ignoreEvent);
  useEventListener(containerRef, "dragover", toggleDrag(true));
  useEventListener(containerRef, "dragenter", toggleDrag(true));
  useEventListener(containerRef, "dragleave", toggleDrag(false));
  useEventListener(containerRef, "dragend", toggleDrag(false));
  useEventListener(containerRef, "drop", filesDropped);

  return { containerRef, isDragover };
};

const FileInputBox = ({ onFileSelect, children }: { onFileSelect: (fs: File[]) => void; children: ReactNode }) => {
  const { containerRef, isDragover } = useDragHandlers(onFileSelect);

  return (
    <Box
      sx={{
        border: 1,
        borderStyle: "dashed",
        borderColor: isDragover ? "primary.main" : "primary.light",
        bgcolor: isDragover ? "highlight.main" : undefined,
      }}
      ref={containerRef}
    >
      {children}
    </Box>
  );
};

export default FileInputBox;
