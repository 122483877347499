import { useContext, useState } from "react";
import { Form } from "@feathery/react";
import { ContextOnChange } from "@feathery/react/dist/types/Form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import config from "@/config";
import { StoreContext } from "@/stores";

const SignatureInput = () => {
  const {
    reportStore: { currentReport, uploadSignature, deleteSignature },
  } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (event: ContextOnChange) => {
    const signatureField = event.getFieldValues().signature;
    if (!(signatureField instanceof Promise)) return;

    event.setFieldValues({ signature: null });

    setIsLoading(true);
    const file = await signatureField;
    await uploadSignature(file).finally(() => setIsLoading(false));
  };

  const onDelete = () => {
    setIsLoading(true);
    deleteSignature().finally(() => setIsLoading(false));
  };

  if (!currentReport) return <></>;

  if (isLoading)
    return (
      <Stack direction="row" justifyContent="center">
        <CircularProgress />
      </Stack>
    );

  if (currentReport.signatureImageUrl)
    return (
      <Stack direction="row" justifyContent="center" gap={4} height={50}>
        <img src={currentReport.signatureImageUrl} alt="Signature" style={{ objectFit: "contain" }} />
        <Button variant="contained" sx={{ bgcolor: "error.main" }} onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    );

  return (
    <Form
      formId={config.signatureFeatheryFormId}
      className="form-wrapper"
      hideTestUI={true}
      onChange={onSubmit}
      initialLoader={{ show: true, loader: <CircularProgress /> }}
    />
  );
};

export default SignatureInput;
