import { Editor, JSONContent, Node, mergeAttributes } from "@tiptap/react";
import { isEmpty, map } from "lodash";
import { mapExists } from "@parallel/vertex/util/collection.util";

const pillStyles = {
  border: "1px solid lightgrey",
  "border-radius": "10px",
  padding: "2px 6px",
  "font-size": "0.75rem",
  "vertical-align": "1px",
  "white-space": "nowrap",
};

const getPillStyleString = (attributes: Record<string, any>) => {
  const styles = attributes.isMissing ? { ...pillStyles, border: "1px solid red" } : pillStyles;
  return map(styles, (value, key) => `${key}: ${value};`).join(" ");
};

export const InjectedValueNode = Node.create({
  name: "injectedValue",

  group: "inline",
  inline: true,
  selectable: false,

  addAttributes() {
    return {
      value: {
        default: null,
        parseHTML: element => element.getAttribute("data-value"),
      },
      tokenPath: {
        default: null,
        parseHTML: element => element.getAttribute("data-tokenpath"),
      },
      isMissing: {
        default: false,
        parseHTML: element => element.classList.contains("missing"),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="injectedValue"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ["span", mergeAttributes({ style: getPillStyleString(HTMLAttributes) }, HTMLAttributes), node.attrs.value];
  },
});

const getTextContentString = ({ type, text, marks }: JSONContent): string | null => {
  if (type !== "text" || !text) return null;

  // TODO handle multiple marks?
  const firstMark = marks && marks[0];

  switch (firstMark?.type) {
    case "bold":
      return `<strong>${text}</strong>`;
    case "italic":
      return `<em>${text}</em>`;
    case "underline":
      return `<u>${text}</u>`;
    default:
      return text;
  }
};

const getNodeContentHtmlString = (node: JSONContent): string | null => {
  const getParentNodeHtml = (parentNode: JSONContent, tag: string, delimiter: string) => {
    const children = mapExists(parentNode.content, getNodeContentHtmlString).join(delimiter);
    return `<${tag}>${children}</${tag}>`;
  };

  switch (node.type) {
    case "injectedValue":
      const injectedTokenPath = node.attrs?.tokenPath;
      return injectedTokenPath ? `{{ ${injectedTokenPath} }}` : node.text || null;
    case "text":
      return getTextContentString(node);
    case "paragraph":
      return getParentNodeHtml(node, "p", "");
    case "listItem":
      return mapExists(node.content, itemChild => {
        switch (itemChild.type) {
          case "paragraph":
            return getParentNodeHtml(itemChild, "li", "");
          default:
            return getNodeContentHtmlString(itemChild);
        }
      }).join("\n");
    case "bulletList":
      return getParentNodeHtml(node, "ul", "\n");
    case "orderedList":
      return getParentNodeHtml(node, "ol", "\n");
    default:
      return null;
  }
};

export const getContentHtmlString = (editor: Editor): string | null => {
  const { content: docContent } = editor.getJSON();
  if (!docContent || isEmpty(docContent)) return null;

  if (docContent[0].type === "paragraph") {
    const paragraphs = mapExists(docContent, getNodeContentHtmlString);
    return paragraphs.join("\n");
  }

  return docContent.map(getNodeContentHtmlString).join("");
};
