import { useContext } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutoCompleteInput from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { AVAILABLE_LANGUAGES, LANGUAGE_NAMES } from "@parallel/vertex/enums/user.enums";
import { MIN_DATE } from "@parallel/vertex/types/shared.types";
import { toUtcDate } from "@parallel/vertex/util/datetime.util";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { StudentInfoParams } from "@/util/student.form.util";

const logger = initLogger("StudentInfoInput", getLoggerContext);

const StudentInfoInput = <P extends StudentInfoParams>({
  params,
  setParams,
  readonly,
}: {
  params: P;
  setParams: (p: P) => void;
  readonly?: boolean;
}) => {
  const {
    apiStore: { institutionApi },
  } = useContext(StoreContext);

  return (
    <>
      <Stack direction="row" gap={1} sx={{ width: "100%" }}>
        <TextField
          fullWidth
          label="First Name"
          value={params.firstName || ""}
          onChange={e => setParams({ ...params, firstName: e.target.value })}
          disabled={readonly}
        />
        <TextField
          fullWidth
          label="Last Name"
          value={params.lastName || ""}
          onChange={e => setParams({ ...params, lastName: e.target.value })}
          disabled={readonly}
        />
      </Stack>
      <DatePicker
        label="Date of Birth"
        minDate={MIN_DATE}
        value={params.birthDate || null}
        onChange={d => setParams({ ...params, birthDate: d ? toUtcDate(d) : undefined })}
        disabled={readonly}
      />
      <AutoCompletePageSearchInput
        label="Campus"
        search={name => institutionApi.searchCampuses({ name }).catch(logger.handleFailure("searchCampuses"))}
        getOption={c => ({
          key: c.institutionId,
          label: c.salesforceName,
          groupName: c.district?.salesforceName || "No District",
        })}
        selected={params.campus}
        onSelect={campus => setParams({ ...params, campus })}
        disabled={readonly}
      />
      <TextField
        label="Email (optional)"
        value={params.email || ""}
        onChange={e => setParams({ ...params, email: e.target.value })}
        disabled={readonly}
      />
      <AutoCompleteInput
        label="Languages"
        options={AVAILABLE_LANGUAGES.map(key => ({ key, label: LANGUAGE_NAMES[key] }))}
        selected={params.languages?.map(key => ({ key, label: LANGUAGE_NAMES[key] })) || []}
        onSelect={selected => setParams({ ...params, languages: selected.map(s => s.key) })}
        disabled={readonly}
      />
    </>
  );
};

export default StudentInfoInput;
