import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Form, getFieldValues, updateUserId } from "@feathery/react";
import { useTheme } from "@mui/material/styles";
import { FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { FormAnswers, formAnswersSchema } from "@parallel/vertex/types/form.types";
import { getLoggerContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("FeatheryForm", getLoggerContext);

const FeatheryForm = ({
  featheryFormId,
  submissionId,
  onSubmit,
  isReadOnly,
}: {
  featheryFormId: string;
  submissionId: string;
  onSubmit: (answers: FormAnswers) => Promise<void>;
  isReadOnly?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    updateUserId(submissionId).then(() => setIsLoading(false));
  }, [submissionId]);

  const theme = useTheme();
  const customLoader = (
    <FullCenterBox>
      <PuffLoader color={theme.palette.grey[500]} size={75} />
    </FullCenterBox>
  );

  if (isLoading) return customLoader;

  const onFormComplete = async () => {
    if (isReadOnly) return;

    const formAnswers = formAnswersSchema.safeParse(getFieldValues())?.data;
    if (!formAnswers) {
      toast.warning("Error parsing form answer data");
      return;
    }

    setIsLoading(true);
    await onSubmit(formAnswers)
      .catch(e => {
        logger.logFailure("updateSubmission", e);
        toast.error("Failed to update form");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Form
      formId={featheryFormId}
      style={{ maxHeight: "100%" }}
      className="form-wrapper"
      initialLoader={{
        show: true,
        loader: customLoader,
      }}
      readOnly={isReadOnly}
      onFormComplete={onFormComplete}
      hideTestUI={true}
      initialValues={{ ispathway: true }}
    />
  );
};

export default FeatheryForm;
