import { ReactElement, cloneElement, useContext } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ScheduleIcon from "@mui/icons-material/Schedule";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningIcon from "@mui/icons-material/Warning";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { SxProps, Theme, styled, useTheme } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";
import { useMeasure } from "@react-hookz/web";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { ApprovalStatus } from "@parallel/vertex/types/approval.types";
import { AppointmentStatus } from "@parallel/vertex/types/calendar/appointment.types";
import { timeRangeString, timeRangeStringTuple } from "@parallel/vertex/util/datetime.util";
import { StoreContext } from "@/stores";

type Size = { height: number; width: number };

const SIZE_BREAKPOINTS = {
  font: {
    small: ({ height, width }: Size) => height < 30 || width < 450,
    tiny: ({ height, width }: Size) => height < 30 || width < 260,
    hidden: ({ height, width }: Size) => height < 12 || width < 50 || (height < 30 && width < 100),
  },
  initials: {
    hidden: ({ height, width }: Size) => height < 20 || width < 210,
  },
  icon: {
    noText: ({ height, width }: Size) => height < 60 || width < 450,
    hidden: ({ height, width }: Size) => height < 20 || width < 210,
  },
};

const isSizeBreak = (breakpoint: (s: Size) => boolean, itemSize: Size = { height: NaN, width: NaN }) =>
  breakpoint(itemSize);

type CalendarItemStyleProps = {
  containerStyle: SxProps;
  initialsStyle: SxProps | null;
  icon?: ReactElement;
};

type CalendarItemDataStatus = AppointmentStatus | ApprovalStatus;

export type CalendarItemData = {
  key: string;
  status?: CalendarItemDataStatus;
  title: string;
  startTime: DateTime;
  endTime: DateTime;
  attendees: { userId: string; initials: string; fullName: string }[];
  onClick: () => void;
};

const statusIcons: Record<CalendarItemDataStatus, ReactElement> = {
  OCCURRED: <EventAvailableIcon />,
  CANCELLED: <EventBusyIcon />,
  LATE_CANCELLED: <ScheduleIcon />,
  NO_SHOW: <VisibilityOffIcon />,
  PENDING: <MoreHorizIcon />,
  APPROVED: <CheckIcon />,
  DENIED: <CancelIcon />,
  FOR_REVIEW: <WarningIcon />,
};

const getStyleProps = (
  data: CalendarItemData,
  size: { height: number; width: number } | undefined,
  theme: Theme,
): CalendarItemStyleProps => {
  const getInitialsStyle = (color: { backgroundColor: string; color: string }) => {
    if (isSizeBreak(SIZE_BREAKPOINTS.initials.hidden, size)) return null;
    if (isSizeBreak(SIZE_BREAKPOINTS.font.small, size)) return { ...color, height: 18, width: 18, fontSize: "0.6rem" };
    return {
      ...color,
      borderWidth: 2,
      borderColor: color.backgroundColor,
      height: 24,
      width: 24,
      fontSize: "0.75rem",
    };
  };

  const getIcon = (icon: ReactElement) => {
    if (isSizeBreak(SIZE_BREAKPOINTS.icon.hidden, size)) {
      return undefined;
    }

    if (isSizeBreak(SIZE_BREAKPOINTS.icon.noText, size)) {
      return cloneElement(icon, {
        sx: { width: "18px", height: "18px", margin: "2px 4px 0 12px" },
      });
    }

    return (
      <Stack alignItems="center" direction="row" mt={0.2}>
        <Typography variant="subtitle1" noWrap>
          {data.status?.replace("_", " ")}
        </Typography>
        {cloneElement(icon, { sx: { width: "20px", height: "20px", margin: "0 4px 0 12px" } })}
      </Stack>
    );
  };

  switch (data.status) {
    case undefined:
    case null:
      return {
        containerStyle: {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.primary.contrastText,
          borderWidth: 2,
          borderColor: theme.palette.info.main,
        },
        initialsStyle: getInitialsStyle({
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
        }),
      };
    case "OCCURRED":
      return {
        containerStyle: {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          borderWidth: 2,
          borderColor: theme.palette.success.main,
        },
        initialsStyle: getInitialsStyle({
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.success.contrastText,
        }),
        icon: getIcon(statusIcons.OCCURRED),
      };
    case "NO_SHOW":
      return {
        containerStyle: {
          backgroundColor: theme.palette.softred.main,
          color: theme.palette.softred.contrastText,
          borderWidth: 2,
          borderColor: theme.palette.softred.main,
        },
        initialsStyle: getInitialsStyle({
          backgroundColor: theme.palette.softred.dark,
          color: theme.palette.softred.contrastText,
        }),
        icon: getIcon(statusIcons.NO_SHOW),
      };
    case "PENDING":
      return {
        containerStyle: {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.text.primary,
          borderWidth: 2,
          borderColor: theme.palette.info.dark,
        },
        initialsStyle: null,
        icon: getIcon(statusIcons.PENDING),
      };
    case "APPROVED":
      return {
        containerStyle: {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.text.primary,
          borderWidth: 2,
          borderColor: theme.palette.success.main,
        },
        initialsStyle: null,
        icon: getIcon(statusIcons.APPROVED),
      };
    case "FOR_REVIEW":
      return {
        containerStyle: {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.text.primary,
          borderWidth: 2,
          borderColor: theme.palette.warning.main,
        },
        initialsStyle: null,
        icon: getIcon(statusIcons.FOR_REVIEW),
      };
    case "DENIED":
      return {
        containerStyle: {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.text.primary,
          borderWidth: 2,
          borderColor: theme.palette.error.main,
        },
        initialsStyle: null,
        icon: getIcon(statusIcons.DENIED),
      };
    default:
      return {
        containerStyle: {
          backgroundColor: theme.palette.grey[400],
          color: theme.palette.text.primary,
          borderWidth: 2,
          borderColor: theme.palette.grey[600],
        },
        initialsStyle: getInitialsStyle({
          backgroundColor: theme.palette.grey[500],
          color: theme.palette.text.primary,
        }),
      };
  }
};

const TooltipLine = styled(Typography)({ paragraph: true });

const CalendarItem = ({ data }: { data: CalendarItemData }) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  const [itemSize, itemRef] = useMeasure<HTMLDivElement>();

  const theme = useTheme();
  const { containerStyle, initialsStyle, icon } = getStyleProps(data, itemSize, theme);

  const tooltipContents = (
    <>
      <TooltipLine variant="subtitle2">{data.title}</TooltipLine>
      <TooltipLine mt={0.5} mb={1} variant="subtitle2">
        {timeRangeString(data, timezone, { withZone: true })}
      </TooltipLine>
      {data.attendees.map(a => (
        <TooltipLine key={a.userId} mb={0.5} variant="body2">
          {a.fullName}
        </TooltipLine>
      ))}
    </>
  );

  let titleVariant: Variant | null = "body1";
  let timeRangeVariant: Variant | null = "body2";
  if (isSizeBreak(SIZE_BREAKPOINTS.font.tiny, itemSize)) {
    titleVariant = "body2";
  }
  if (isSizeBreak(SIZE_BREAKPOINTS.font.hidden, itemSize)) {
    titleVariant = null;
    timeRangeVariant = null;
  }

  const [startTime, endTime] = timeRangeStringTuple(data, timezone, { withZone: true });

  return (
    <Tooltip title={tooltipContents} placement="bottom-start">
      <FullStack
        onClick={data.onClick}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ p: 0.5, borderRadius: "4px", cursor: "pointer", overflow: "hidden", ...containerStyle }}
        ref={itemRef}
      >
        <Stack direction="row" gap={2} alignItems="baseline" maxWidth="100%">
          <Stack direction="row" columnGap={1} alignItems="baseline" flexWrap="wrap" maxWidth="100%">
            {titleVariant && (
              <Typography
                variant={titleVariant}
                lineHeight="120%"
                maxWidth="100%"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {data.title}
              </Typography>
            )}
            {timeRangeVariant && (
              <Typography variant={timeRangeVariant} lineHeight="120%" noWrap>
                {startTime} - <wbr />
                {endTime}
              </Typography>
            )}
          </Stack>

          {initialsStyle && (
            <Stack direction="row" gap={1}>
              {data.attendees.map(s => (
                <Avatar sx={initialsStyle} key={s.userId} variant="rounded">
                  {s.initials}
                </Avatar>
              ))}
            </Stack>
          )}
        </Stack>

        {icon}
      </FullStack>
    </Tooltip>
  );
};

export default observer(CalendarItem);
