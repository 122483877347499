import { useContext, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { pick } from "lodash";
import { SingleReport } from "@parallel/vertex/types/assessment/assessment.report.types";
import { UpdateStudentBody, updateStudentBodySchema } from "@parallel/vertex/types/user/student.types";
import { toUtcDate } from "@parallel/vertex/util/datetime.util";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SelectInput from "@/components/shared/input/SelectInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("StudentInformationForm", getLoggerContext);

const PRONOUN_OPTIONS = ["he/him/his", "she/her/her", "they/them/their", "ze/zir/zirs", "sie/hir/hirs"];

const GRADE_OPTIONS = [
  "Pre-K",
  "Kindergarten",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
];

const ReportStudentForm = ({ report, onFinished }: { report: SingleReport; onFinished: () => void }) => {
  const {
    apiStore: { institutionApi },
    reportStore: { updateCurrentReportStudent },
  } = useContext(StoreContext);

  const [params, setParams] = useState({
    ...pick(report.client, "firstName", "lastName", "birthDate"),
    campus: report.client.campus ? { key: report.client.campus.id, label: report.client.campus.name } : null,
    pronouns: report.client.pronouns || undefined,
    grade: report.client.grade || undefined,
  });

  const formContent = (
    <>
      <Stack direction="row" gap={1} sx={{ width: "100%" }}>
        <TextField
          fullWidth
          label="First Name"
          value={params.firstName || ""}
          onChange={e => setParams({ ...params, firstName: e.target.value })}
        />
        <TextField
          fullWidth
          label="Last Name"
          value={params.lastName || ""}
          onChange={e => setParams({ ...params, lastName: e.target.value })}
        />
      </Stack>
      <DatePicker
        label="Date of Birth"
        value={params.birthDate || null}
        onChange={d => setParams({ ...params, birthDate: d ? toUtcDate(d) : undefined })}
      />
      <SelectInput
        label="Gender Pronouns"
        options={PRONOUN_OPTIONS.map(value => ({ key: value, label: value }))}
        value={params.pronouns}
        onChange={pronouns => setParams({ ...params, pronouns })}
        fullWidth
      />
      <AutoCompletePageSearchInput
        label="Campus"
        search={name => institutionApi.searchCampuses({ name }).catch(logger.handleFailure("searchCampuses"))}
        getOption={c => ({
          key: c.institutionId,
          label: c.salesforceName,
          groupName: c.district?.salesforceName || "No District",
        })}
        selected={params.campus}
        onSelect={campus => setParams({ ...params, campus })}
      />
      <SelectInput
        fullWidth
        label="Grade"
        options={GRADE_OPTIONS.map(value => ({ key: value, label: value }))}
        value={params.grade}
        onChange={grade => setParams({ ...params, grade })}
      />
    </>
  );

  const onSubmit = async (body: UpdateStudentBody) => {
    await updateCurrentReportStudent(body).catch(logger.handleFailureAndThrow("updateStudent", { level: "warning" }));
    onFinished();
  };

  return (
    <SubmitForm
      operationName="update"
      recordName="student info"
      formContent={formContent}
      params={params}
      validate={params => updateStudentBodySchema.safeParse({ ...params, campusId: params.campus?.key })?.data}
      onSubmit={onSubmit}
      onCancel={onFinished}
    />
  );
};

export default ReportStudentForm;
