import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";

const ConfirmDialog = ({
  isOpen,
  text,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <ProcessButton process={() => onSubmit().then(onClose)}>Yes</ProcessButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
