import { useContext, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReportContentLayout } from "@/components/report/ReportContent";
import DocumentsUpload from "@/components/report/data/upload/DocumentsUpload";
import { StoreContext } from "@/stores";

const ReportDocuments = () => {
  const {
    reportStore: { currentReport },
  } = useContext(StoreContext);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  if (!currentReport) return <></>;

  return (
    <ReportContentLayout
      title="Documents"
      actions={
        <Button startIcon={<UploadIcon />} onClick={() => setUploadModalOpen(true)}>
          Upload Documents
        </Button>
      }
    >
      <DocumentsUpload uploadModalOpen={uploadModalOpen} setUploadModalOpen={setUploadModalOpen} />
    </ReportContentLayout>
  );
};

export default observer(ReportDocuments);
