import { useContext, useState } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { capitalize, isUndefined } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { StatusIcon, useDebouncedProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import { InterviewFormType } from "@parallel/vertex/enums/report.enums";
import { FormAnswers } from "@parallel/vertex/types/form.types";
import { getInterviews, INTERVIEW_TYPE_LABELS } from "@parallel/vertex/util/assessment.report.util";
import { getPossessiveNoun } from "@parallel/vertex/util/string.util";
import { ReportContentLayout } from "@/components/report/ReportContent";
import FeatheryForm from "@/components/shared/display/FeatheryForm";
import CenterModal from "@/components/shared/layout/CenterModal";
import ConfirmDialog from "@/components/shared/layout/ConfirmDialog";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ReportTestingPlan", getLoggerContext);

const ReportInterviewNotes = ({
  submissionId,
  interviewType,
}: {
  submissionId: string;
  interviewType: InterviewFormType;
}) => {
  const {
    reportStore: {
      currentReport: report,
      submitInterviewSubmission,
      updateIntervieweeName,
      sendInterviewEmail,
      deleteInterviewSubmission,
    },
  } = useContext(StoreContext);

  const submission = getInterviews(report, interviewType).find(s => s.submissionId === submissionId);

  const [stagedName, setStagedName] = useState(submission?.intervieweeName || "");

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [sendToEmail, setSendToEmail] = useState<string>();

  const { status: nameStatus, perform: updateName } = useDebouncedProcessStatus(
    (intervieweeName: string) => updateIntervieweeName(interviewType, submissionId, intervieweeName),
    { saveKey: submissionId },
  );
  const onNameUpdate = (name: string) => {
    setStagedName(name);
    updateName(name);
  };

  if (!report) return <></>;
  if (!submission) return <></>;

  const onSubmit = async (formAnswers: FormAnswers) => {
    if (!submission) return;
    await submitInterviewSubmission(interviewType, submissionId, formAnswers)
      .then(() => {
        toast.success(`Successfully submitted ${interviewType} interview`);
      })
      .catch(logger.handleFailureAndThrow("submitInterviewNotes", { level: "warning" }));
  };

  const sendEmail = async () => {
    if (!sendToEmail) return;
    return sendInterviewEmail(interviewType, submissionId, sendToEmail)
      .catch(logger.handleFailureAndThrow("sendEmail", { level: "warning" }))
      .finally(() => setSendToEmail(undefined));
  };

  const deleteSubmission = async () => {
    if (!submission) return;
    return deleteInterviewSubmission(interviewType, submissionId)
      .then(() => {
        toast.success(`Successfully deleted ${interviewType} interview`);
      })
      .catch(logger.handleFailureAndThrow("deleteInterviewSubmission", { level: "warning" }))
      .finally(() => setShowDeleteConfirmation(false));
  };

  const typeLabel = INTERVIEW_TYPE_LABELS[interviewType];

  const actions = (
    <Stack direction="row" alignItems="center" gap={2}>
      {interviewType !== "student" && (
        <TextField
          label={`${typeLabel} Name`}
          value={stagedName}
          onChange={e => onNameUpdate(e.target.value)}
          size="small"
          sx={{ width: 250 }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <StatusIcon status={nameStatus} />
                </InputAdornment>
              ),
            },
          }}
        />
      )}
      <Button
        startIcon={<EmailIcon />}
        onClick={() => setSendToEmail((interviewType === "student" && report.client.email) || "")}
      >
        {submission.email ? "Re-send" : "Send"} to {interviewType}
      </Button>
      <Button startIcon={<DeleteIcon />} onClick={() => setShowDeleteConfirmation(true)} sx={{ color: "error.main" }}>
        Delete
      </Button>
    </Stack>
  );

  return (
    <ReportContentLayout
      title={`${typeLabel} Input`}
      actions={actions}
      headerSx={{ pl: 2, width: 860, alignItems: "center" }}
    >
      {submission.email && (
        <Typography variant="body1" sx={{ py: 2, pl: 2, fontStyle: "italic" }}>
          Last sent to {submission.email.sentTo} on {submission.email.sentAt.toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
      )}
      <FeatheryForm
        featheryFormId={submission.featheryFormId}
        submissionId={submission.submissionId}
        onSubmit={onSubmit}
        isReadOnly={submission.hasAnswers}
        key={submission.submissionId}
      />

      <ConfirmDialog
        isOpen={showDeleteConfirmation}
        text="Are you sure you want to delete this interview?"
        onClose={() => setShowDeleteConfirmation(false)}
        onSubmit={deleteSubmission}
      />
      <CenterModal isOpen={!isUndefined(sendToEmail)} onClose={() => setSendToEmail(undefined)}>
        <PromptLayout
          headerText="Send Input Form"
          leftAction={{ fn: () => setSendToEmail(undefined), label: "Cancel" }}
          rightAction={{ fn: sendEmail, label: "Send", icon: <EmailIcon />, disabled: !sendToEmail }}
        >
          <Stack width={400} gap={2}>
            <Typography variant="body1">
              Enter {getPossessiveNoun(stagedName || interviewType)} email here to send them this input form.
            </Typography>
            <TextField
              label={`${capitalize(interviewType)} Email`}
              value={sendToEmail}
              onChange={e => setSendToEmail(e.target.value)}
            />
          </Stack>
        </PromptLayout>
      </CenterModal>
    </ReportContentLayout>
  );
};

export default observer(ReportInterviewNotes);
