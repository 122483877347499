import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadIcon from "@mui/icons-material/Upload";
import { Button, IconButton, Link, Stack, Typography } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridDeleteIcon } from "@mui/x-data-grid-premium";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import FileInputBox from "@parallel/polygon/components/shared/input/FileInputBox";
import VisuallyHiddenInput from "@parallel/polygon/components/shared/input/VisuallyHiddenInput";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { ReportDocumentFileTypes } from "@parallel/vertex/enums/report.enums";
import SelectInput from "@/components/shared/input/SelectInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext, getLoggerContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { FileState } from "@/util/report.util";

const logger = initLogger("ReportDocuments", getLoggerContext);

type ReportDocumentFileState = FileState & { documentType?: string };

type FormattedReportDocumentUpload = {
  id: string;
  name: string;
  documentType?: string;
  // createdBy: string;
  createdAt: DateTime;
};

interface DocumentsUploadProps {
  uploadModalOpen: boolean;
  setUploadModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DocumentsUpload = ({ uploadModalOpen, setUploadModalOpen }: DocumentsUploadProps) => {
  const {
    reportStore: { currentReport, uploadDocuments, deleteDocumentUpload },
  } = useContext(StoreContext);

  const columns = useMemo<GridColDef<FormattedReportDocumentUpload>[]>(
    () => [
      { field: "name", headerName: "Document Name" },
      { field: "documentType", headerName: "Document Type" },
      { field: "createdBy", headerName: "Added By" },
      { field: "createdAt", headerName: "Date Added" },
      {
        field: "actions",
        type: "actions" as const,
        width: 80,
        getActions: (params: FormattedReportDocumentUpload) => [
          <GridActionsCellItem
            icon={<GridDeleteIcon />}
            label="Delete"
            onClick={() => deleteDocumentUpload(params.id)}
            key={params.id}
          />,
        ],
      },
    ],
    [deleteDocumentUpload],
  );

  const [files, setFiles] = useState<ReportDocumentFileState[]>([]);
  const [formattedUploads, setFormattedUploads] = useState<FormattedReportDocumentUpload[]>([]);
  const [isValid, setIsValid] = useState(false);

  const validNonImageFileTypes = [
    "pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const isValidImageType = (file: File) => file.type.split("/")[0] === "image";

  const checkIsValid = (filesToAppend: ReportDocumentFileState[]) => {
    setIsValid(
      filesToAppend.length > 0 &&
        filesToAppend.every(
          ({ file, documentType }) =>
            validNonImageFileTypes.includes(file.type) || (isValidImageType(file) && documentType),
        ),
    );
  };

  const appendFiles = (newFiles: File[]) => {
    const newFileState = newFiles.map(file => {
      return { file };
    });
    setFiles([...files, ...newFileState]);
  };

  const updateFileAtIndex = (index: number, update: Partial<ReportDocumentFileState>) =>
    setFiles(files.map((f, i) => (i === index ? { ...f, ...update } : f)));

  const deleteFileAtIndex = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  useEffect(() => {
    checkIsValid(files);
  }, [files]);

  useEffect(() => {
    if (!currentReport) return;

    setFormattedUploads(
      currentReport.documentUploads.map(({ createdAt, reportDocumentUploadId, fileName, documentType }) => ({
        id: reportDocumentUploadId,
        name: fileName,
        documentType: ReportDocumentFileTypes.find(s => s.key === documentType)?.label,
        createdAt,
      })),
    );
  }, [currentReport?.documentUploads.length]);

  const uploadFiles = async () => {
    if (!isValid) return;
    await uploadDocuments(files)
      .then(() => {
        setFiles([]);
        setIsValid(false);
      })
      .catch(logger.handleFailureAndThrow("uploadDocumentFiles"));
    setUploadModalOpen(false);
  };

  if (!currentReport)
    return (
      <Stack gap={1} pb={2}>
        <Typography variant="body1">No document uploads</Typography>
      </Stack>
    );

  return (
    <>
      <DataGridPremium columns={columns} rows={formattedUploads} />

      <CenterModal isOpen={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
        <Stack gap={2} width={800}>
          <Typography variant="h2">Upload Documents</Typography>
          <FileInputBox onFileSelect={appendFiles}>
            <Stack justifyContent="center" alignItems="center" height={200}>
              <NoteAddIcon sx={{ fontSize: 80, color: "primary.light" }} />
              <Link component="label" sx={{ cursor: "pointer" }}>
                Select files to upload
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={e => e.target.files && appendFiles([...e.target.files])}
                />
              </Link>
              <Typography variant="body2">or drag and drop here</Typography>
            </Stack>
          </FileInputBox>

          <Stack width="100%" maxHeight={400} sx={{ overflowY: "auto" }}>
            {files.map((fileState, i) => {
              return (
                <Stack direction="row" width="100%" p={1} gap={2} alignItems="center" key={i}>
                  <NoteAddIcon sx={{ color: "primary.main" }} />
                  <Stack sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">{fileState.file.name}</Typography>
                  </Stack>
                  <SelectInput
                    label="Document Type"
                    options={ReportDocumentFileTypes}
                    value={fileState.documentType}
                    onChange={documentType => updateFileAtIndex(i, { documentType })}
                    width={230}
                    size="small"
                  />
                  <IconButton onClick={() => deleteFileAtIndex(i)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              );
            })}
          </Stack>

          <Stack direction="row" width="100%" justifyContent="space-between">
            <Button variant="text" onClick={() => setUploadModalOpen(false)}>
              Cancel
            </Button>
            <ProcessButton variant="contained" disabled={!isValid} process={uploadFiles} startIcon={<UploadIcon />}>
              Upload
            </ProcessButton>
          </Stack>
        </Stack>
      </CenterModal>
    </>
  );
};

export default observer(DocumentsUpload);
