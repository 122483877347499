import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddchartIcon from "@mui/icons-material/Addchart";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import InfoIcon from "@mui/icons-material/Info";
import { useMountEffect } from "@react-hookz/web";
import { pick } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { UserType } from "@parallel/vertex/enums/user.enums";
import {
  ExtendedAssessmentEnrollment,
  ExtendedReport,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import { filterExists } from "@parallel/vertex/util/collection.util";
import CreateReportForm from "@/components/report/CreateReportForm";
import ReportList, { FetchParams } from "@/components/report/ReportList";
import { FilterProperty } from "@/components/shared/input/FilterSearchInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import { DataTableColumn } from "@/components/shared/layout/DataTable";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import SubHeader from "@/components/shared/layout/SubHeader";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type PendingReportRow = ExtendedUser & {
  serviceLineClientId: string;
  serviceType: string;
  consentSignedAt?: DateTime;
  assessmentDueDate?: DateTime;
};

type ReportRow = ExtendedAssessmentEnrollment & {
  studentName: string;
  reportType: string;
  status: string;
  reviewerName: string;
  providerName?: string;
};

const PENDING_REPORT_COLUMNS: DataTableColumn<PendingReportRow>[] = [
  {
    key: "serviceLineClientId",
    header: "Service Enrollment ID",
    hidden: true,
    isID: true,
    sortable: false,
  },
  { key: "userId", header: "ID", hidden: true, sortable: false },
  {
    key: "fullName",
    header: "Name",
  },
  {
    key: "serviceType",
    header: "Service Type",
    flexWidth: 2,
  },
  {
    key: "consentSignedAt",
    header: "Consent Signed At",
    type: "date",
  },
  {
    key: "assessmentDueDate",
    header: "Assessment Due Date",
    type: "date",
  },
];

const getReportColumns = (listType: "active" | "complete", userType?: UserType): DataTableColumn<ReportRow>[] =>
  filterExists([
    { key: "reportId", header: "ID", hidden: true, isID: true, sortable: false },
    {
      key: "studentName",
      header: "Name",
    },
    {
      key: "reportType",
      header: "Report Type",
      flexWidth: 2,
    },
    listType === "active" && {
      key: "status",
      header: "Status",
      sortable: false,
    },
    {
      key: "reviewerName",
      header: "Reviewer",
    },
    {
      key: "assessmentDueDate",
      header: "Assessment Due Date",
      type: "date",
    },
    {
      key: "providerName",
      header: "Provider Name",
      hidden: userType !== "ADMIN",
    },
  ]);

const PENDING_REPORT_FILTER_PROPERTIES: FilterProperty[] = [
  { propertyName: "Student Name", searchParamKey: "name" },
  { propertyName: "Service Type", searchParamKey: "serviceLineTitle" },
];

const REPORT_FILTER_PROPERTIES: FilterProperty[] = [
  { propertyName: "Student Name", searchParamKey: "studentName" },
  { propertyName: "Reviewer Name", searchParamKey: "reviewerName" },
  { propertyName: "Report Type", searchParamKey: "serviceLineTitle" },
];

const toPendingReportRowResult = (
  result: PaginatedResult<ExtendedAssessmentEnrollment>,
): PaginatedResult<PendingReportRow> => ({
  ...result,
  records: result.records.map(enrollment => ({
    ...enrollment.client,
    serviceType: enrollment.serviceLine.title,
    ...pick(enrollment, "serviceLineClientId", "consentSignedAt", "assessmentDueDate"),
  })),
});

const toReportRowResult = (result: PaginatedResult<ExtendedReport>): PaginatedResult<ReportRow> => ({
  ...result,
  records: result.records.map(report => ({
    ...report,
    studentName: report.client.fullName,
    reportType: report.serviceLine.title,
    status: report.approval ? "In Review" : "In Progress",
    reviewerName: report.reviewer.fullName,
    providerName: report.provider.fullName,
  })),
});

const logger = initLogger("ReportHomeScreen", getLoggerContext);

const REPORT_LIST_TYPES = ["pending", "active", "complete"] as const;
type ReportListType = (typeof REPORT_LIST_TYPES)[number];

const LIST_TYPE_HEADERS = {
  pending: { label: "Assigned", icon: <InfoIcon /> },
  active: { label: "Active", icon: <FilePresentIcon /> },
  complete: { label: "Completed", icon: <EmojiEventsIcon /> },
};

const ReportHomeScreen = () => {
  const {
    apiStore: { reportApi },
    authStore: { currentUser },
    reportStore: { loadCreateReportEnrollment, setCreateReportEnrollment, createReportEnrollment },
  } = useContext(StoreContext);

  const { serviceLineClientId } = useParams();
  useEffect(() => {
    loadCreateReportEnrollment(serviceLineClientId);
  }, [serviceLineClientId]);

  const [currentList, setCurrentList] = useState<ReportListType>("active");

  const [pendingEnrollments, setPendingEnrollments] = useState<PaginatedResult<ExtendedAssessmentEnrollment>>();
  const [activeRows, setActiveRows] = useState<PaginatedResult<ReportRow>>();
  const [completeRows, setCompleteRows] = useState<PaginatedResult<ReportRow>>();

  const fetchRecords = (
    listType: ReportListType,
    { page = { pageSize: 25 }, filters = [], sort }: FetchParams = {},
  ) => {
    const commonParams = filters.reduce(
      (currentParams, nextFilter) => ({
        ...currentParams,
        [nextFilter.searchParamKey]: nextFilter.searchParamValue,
      }),
      { ...page, ...sort },
    );
    switch (listType) {
      case "pending":
        return reportApi
          .searchAssessmentEnrollments({ reportId: null, ...commonParams })
          .then(setPendingEnrollments)
          .catch(logger.handleFailure("fetchPendingEnrollments"));
      case "active":
        return reportApi
          .searchReports({ isCompleted: false, ...commonParams })
          .then(toReportRowResult)
          .then(setActiveRows)
          .catch(logger.handleFailure("fetchActiveReports"));
      case "complete":
        return reportApi
          .searchReports({ isCompleted: true, ...commonParams })
          .then(toReportRowResult)
          .then(setCompleteRows)
          .catch(logger.handleFailure("fetchCompleteReports"));
    }
  };
  useMountEffect(() => REPORT_LIST_TYPES.map(listType => fetchRecords(listType)));

  const navigation = {
    tabs: REPORT_LIST_TYPES.map(listType => ({
      key: listType,
      ...LIST_TYPE_HEADERS[listType],
    })),
    currentKey: currentList,
    onChange: setCurrentList,
  };
  const header = <SubHeader icon={<AssessmentIcon />} title="Report Home" navigation={navigation} />;

  const navigate = useNavigate();

  return (
    <PrimaryLayout headerContent={header}>
      <FullBox sx={{ py: 2 }}>
        {currentList === "pending" && (
          <ReportList
            columns={PENDING_REPORT_COLUMNS}
            currentPage={pendingEnrollments && toPendingReportRowResult(pendingEnrollments)}
            fetchPage={params => fetchRecords("pending", params)}
            filters={PENDING_REPORT_FILTER_PROPERTIES}
            actions={[
              {
                size: "full",
                icon: <AddchartIcon />,
                label: "Create Assessment",
                onClick: id => {
                  setCreateReportEnrollment(pendingEnrollments?.records.find(s => s.serviceLineClientId === id));
                  navigate(`/report/start/${id}`);
                },
                width: 200,
              },
            ]}
          />
        )}
        {currentList === "active" && (
          <ReportList
            columns={getReportColumns("active", currentUser?.userType)}
            currentPage={activeRows}
            fetchPage={params => fetchRecords("active", params)}
            filters={REPORT_FILTER_PROPERTIES}
            onRowClick={reportId => navigate(`/report/${reportId}`)}
          />
        )}
        {currentList === "complete" && (
          <ReportList
            columns={getReportColumns("complete", currentUser?.userType)}
            currentPage={completeRows}
            fetchPage={params => fetchRecords("complete", params)}
            filters={REPORT_FILTER_PROPERTIES}
            onRowClick={reportId => navigate(`/report/${reportId}`)}
          />
        )}
      </FullBox>

      <CenterModal isOpen={!!createReportEnrollment} onClose={() => navigate("/report")} minWidth={400}>
        {createReportEnrollment && (
          <CreateReportForm
            enrollment={createReportEnrollment}
            onCreated={reportId => navigate(`/report/${reportId}`)}
            onClose={() => navigate("/report")}
          />
        )}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default observer(ReportHomeScreen);
