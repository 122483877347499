import { useContext } from "react";
import Stack from "@mui/material/Stack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTime } from "luxon";
import { MIN_DATE } from "@parallel/vertex/types/shared.types";
import { StoreContext } from "@/stores";

type TimeRange = {
  startTime?: DateTime;
  endTime?: DateTime;
};

const TimeRangeInput = ({
  value,
  onChange,
  minStart = MIN_DATE,
  maxStart,
}: {
  value: TimeRange;
  onChange: (newValue: TimeRange) => void;
  minStart?: DateTime;
  maxStart?: DateTime;
}) => {
  const {
    authStore: { timezone, shortTimezone },
  } = useContext(StoreContext);

  const zonedValue = {
    startTime: value.startTime?.setZone(timezone),
    endTime: value.endTime?.setZone(timezone),
  };

  // TimeInput min/max validation ignores date, so only constrain time if value is the day of the min/max prop
  const getStartTimeConstraint = (datetime?: DateTime) => {
    if (!datetime || !zonedValue?.startTime) return;
    const zoned = datetime.setZone(timezone);
    return zoned.hasSame(zonedValue.startTime, "day") ? zoned : undefined;
  };

  return (
    <>
      <DatePicker
        label="Date"
        value={zonedValue.startTime || null}
        minDate={minStart}
        maxDate={maxStart}
        onChange={d =>
          onChange({
            startTime: d?.isValid
              ? zonedValue.startTime?.set({ year: d.year, month: d.month, day: d.day }) || d
              : undefined,
            endTime: d?.isValid
              ? zonedValue.endTime?.set({ year: d.year, month: d.month, day: d.day }) || d
              : undefined,
          })
        }
      />

      <Stack direction="row" gap={1}>
        <TimePicker
          label={`Starts At (${shortTimezone})`}
          value={zonedValue.startTime || null}
          minTime={getStartTimeConstraint(minStart)}
          maxTime={getStartTimeConstraint(maxStart)}
          onChange={d =>
            onChange({
              startTime: zonedValue.startTime?.set({ hour: d?.hour || 0, minute: d?.minute || 0 }),
              endTime: zonedValue.endTime,
            })
          }
          sx={{ width: "100%" }}
          slotProps={{
            textField: props => ({
              inputProps: {
                // eslint-disable-next-line react/prop-types
                ...props.inputProps,
                "data-testid": "range-input-start",
              },
            }),
          }}
        />
        <TimePicker
          label={`Ends At (${shortTimezone})`}
          value={zonedValue.endTime || null}
          minTime={zonedValue.startTime}
          onChange={d =>
            onChange({
              startTime: zonedValue.startTime,
              endTime: zonedValue.endTime?.set({ hour: d?.hour || 0, minute: d?.minute || 0 }),
            })
          }
          sx={{ width: "100%" }}
          slotProps={{
            textField: props => ({
              inputProps: {
                // eslint-disable-next-line react/prop-types
                ...props.inputProps,
                "data-testid": "range-input-end",
              },
            }),
          }}
        />
      </Stack>
    </>
  );
};

export default TimeRangeInput;
